<template>
  <div class="pt-2 pb-5">
    <div class="bg">
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <slot name="listTitle">
            <h6>{{ listTitle }}</h6>
          </slot>
        </div>
        <form class="d-flex my-1">
          <div class="input-group">
            <input
              type="text"
              v-model="mxSearch"
              class="form-control"
              placeholder=""
              aria-label="Example text with button addon"
            />
            <button type="submit" class="style_btn btn btn-primary" id="button-query">
              <i class="bi bi-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="bg-white p-3 border shadow-0">
        <div class="table-responsive">
          <b-table
            show-empty
            empty-text="Aucune donnée à afficher"
            :fields="tableFields"
            class="align-middle"
            striped
            select-mode="multi"
            ref="table"
            :sort-by.sync="mxPagination.sortBy"
            :sort-desc.sync="mxPagination.sortDesc"
            responsive="sm"
            selectable
            sort-icon-left
            @row-selected="onRowSelected"
            :items="tableData"
          >
            <template #head()="data">
              <span>{{ data.label }} </span>
            </template>

            <template #head(#)="data">
              <div class="form-check form-check-inline">
                <input
                  @change="selectAll"
                  class="form-check-input"
                  type="checkbox"
                  :id="`${data.index}`"
                  name="disponible"
                  :value="false"
                />
              </div>
            </template>
            <template #cell(#)="data">
              <div class="form-check form-check-inline">
                <!-- <button class="btn btn-sm btn-outline-primary" @click="data.toggleDetails"><i class="bi bi-journal-check"></i></button> -->
                <input
                  @input="selectionChanged($event, data)"
                  :checked="data.rowSelected"
                  class="form-check-input"
                  type="checkbox"
                  :id="`${data.index}`"
                  name="disponible"
                />
              </div>
            </template>

            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(enabled)="data">
              <div class="form-check form-switch">
                <!-- <input class="form-check-input" v-model="data.item.enabled" type="checkbox" id="flexSwitchCheckChecked" :checked="data.item.enabled"> -->
                <label class="form-check-label" for="flexSwitchCheckChecked">{{
                  data.item.enabled ? "Actif" : "Inactif"
                }}</label>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="d-flex justify-content-end">
                <a
                  @click.prevent="editReferentiel(data.item)"
                  class="btn btn-success rounded-0 btn-sm me-1"
                  ><i class="bi bi-pencil-square"></i
                ></a>
                <a class="btn btn-info rounded-0 btn-sm me-1"
                  ><i class="bi bi-info-square"></i
                ></a>
                <a
                  @click.prevent="destroyReferentiel(data.item)"
                  class="btn btn-danger rounded-0 btn-sm me-1"
                  ><i class="bi bi-trash"></i
                ></a>
              </div>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              <span>{{ data.value }}</span>
            </template>
            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
                  <b-col>{{ row.item.age }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
                  <b-col>{{ row.item.isActive }}</b-col>
                </b-row>

                <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
              </b-card>
            </template>
          </b-table>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p>
            Affichage de l’élément {{ mxPagination.page * mxPagination.rowsPerPage }} à
            {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
            {{ mxTotalCount }} éléments
          </p>
          <b-pagination
            v-model="mxPagination.page"
            :total-rows="mxTotalCount"
            :per-page="mxPagination.rowsPerPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions } from 'vuex'
import { paginatorMixin } from "@/mixins/paginator-mixin";

export default {
  props: {
    listTitle: {
      type: String,
      default: "Référentiel non précisé",
    },
    referentielName: {
      type: String,
      required: true,
    },
    tableFields: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    emptyReferentielItem: {
      type: Object,
      required: true,
    },
    dataFormatter: {
      type: Function,
    },
    extraQueryArgsParamBuilder: {
      type: Function,
      default: () => {},
    },
    paginatorConfig: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      saved: [],
      search: "",
      filterOptions: [],
      filters: {},
      activeReferentiel: { ...this.emptyReferentielItem },
    };
  },
  watch: {
    mxLoading(val) {
      if (val && this.selected.length !== 0) {
        this.saved = [...this.selected];
      } else if (!val && this.saved.length !== 0) {
        this.selected = [...this.saved];
        this.tableData.forEach((el, index) => {
          this.$refs.table.selectRow(index);
        });
      }
    },
    selected() {
      if (this.selected.length === 0 && this.saved.length !== 0) {
        this.selected = [...this.saved];
      } else if (this.selected.length !== 0) {
        this.$emit("input", this.selected);
      }
    },
  },
  created() {
    this.mxInitializePaginator({
      queryModel: this.referentielName,
      search: this.search,
      fetcherMethod: `${this.referentielName}/fetch${this.referentielCaptilizedName}s`,
      dataGetter: `${this.referentielName}/${this.referentielName}s`,
      paginationGetter: `${this.referentielName}/pagination`,
      autoSubmitQuery: false,
      pagination: {
        sortBy: "nom",
        sortDesc: false,
        descending: false,
        page: 0,
        rowsPerPage: 10,
      },
      filterOptions: this.filterOptions,
      searchFields: ["nom", "phone"],
      extraQueryArgsParamBuilder: this.extraQueryArgsParamBuilder,
    });
  },
  computed: {
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    referentielCaptilizedName() {
      let lowerName = this.referentielName;
      return lowerName[0].toUpperCase() + lowerName.slice(1, lowerName.length);
    },
    referentielLowercaseName() {
      return this.referentielName; //Not use toLowerCase
    },
  },
  methods: {
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
  },
};
</script>

<style></style>
