<template>
  <div class="">
    <PDialog v-if="!inline" :header="title" :modal="true" :visible.sync="display" :containerStyle="{width: '60vw'}">
      <SearchableItemActiviteParticipantSelector
        :listTitle="`Liste des participant à l\'activite ${activite != null? activite.titre : '...' }`"
        :referentielName="referentielName"
        :tableFields="tableFields"
        v-model="editableItem.participants"
        :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
        :emptyReferentielItem="emptyReferentielItem"
      />
      <template #footer>
        <PButton label="Annuler" icon="pi pi-times" @click="cancel" class="p-button-text"/>
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
    <template v-else>
      <SearchableItemActiviteParticipantSelector
        :listTitle="`Liste des participant à l\'activite ${activite != null? activite.titre : '...' }`"
        :referentielName="referentielName"
        :tableFields="tableFields"
        v-model="editableItem.participants"
        :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
        :emptyReferentielItem="emptyReferentielItem"
      />
    </template>
  </div>
</template>

<script>
import SearchableItemActiviteParticipantSelector from '../../common/SearchableItemActiviteParticipantSelector.vue'

export default {
  props: {
    title: {
      type:  String,
      required: true
    },
    inline: {
      type:  Boolean,
      default: true
    },
    activite: {
      type:  Object,
      default: () => ({ })
    }
  },
  components: {
    SearchableItemActiviteParticipantSelector
  },
  data () {
    return {
      display: false,
      editableItem: {
        datePrevue: null,
        typeActivite: null,
        participants: []
      }
    }
  },
  created () {
  },
  watch: {
    item () {
      this.editableItem = { ...this.item }
    }
  },
  computed: {
    referentielName () {
      return 'participation'
    },
    emptyReferentielItem () {
      return {
        titre: null,
        code: null
      }
    },
    tableFields () {
      return [
        '#',
        'index',
        { key: 'participant.nom', label: 'Nom' },
        { key: 'participant.prenom', label: 'Prénom' },
        { key: 'participant.phone', label: 'Téléphone' },
        { key: 'participant.commune.nom', label: 'Commune' },
      ]
    },
    options () {
      return this.structures.map(item => ({ value: item, text: item.libelle }) )
    },
  },
  methods: {
    extraQueryArgsParamBuilder(){
      return `activiteId=${this.activite.id}`
    },
    show () {
      this.display = true
    },
    hide () {
      this.display = false;
    },
    ok () {
      this.$emit('saved', this.editableItem)
      this.editableItem = {}
    },
    cancel () {
      this.hide()
      this.$emit('canceled')
    },
    handleInput(e){
      this.editableItem[e.target.name] = e.target.value
      this.$emit('input', this.editableItem)
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async save(){
      if(!await this.isCompleted()) return;
      
      this.$emit('saved', this.editableItem)
      this.hide()
    },
  }
}
</script>