<template>
  <div class="container-fluid">
    <activite-statistic ref="activiteStatistic" class="mt-3 mb-2" />
    <!-- <button class="" @click="toggleWeekends">toggle weekends</button> -->
    <CDetailViewer
      v-if="activeActivite"
      title="Vue détaillé des activités"
      ref="detailViewer"
      :item="activeActivite"
    >
      <PTabView :activeIndex.sync="activeTab">
        <PTabPanel header="Détail activité">
          <ActiviteDetail :activiteId="activeActivite.id" :item="activeActivite" />
        </PTabPanel>
        <PTabPanel header="Liste des participants">
          <ActiviteParticipantList
            :title="'Liste des participants'"
            :activite="activeActivite"
          />
        </PTabPanel>
        <PTabPanel header="Document joint"> </PTabPanel>
      </PTabView>
    </CDetailViewer>
    <div class="container- mt-4">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div v-if="false" class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de début</label> -->
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de fin</label> -->
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <!-- <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                    </span> -->
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <p-card class="shadow-none">
        <template #title>
          <div
            class="d-flex bg-grey flex-wrap justify-content-between align-items-center"
          >
            <div class="my-1 flex-fill">
              <h4>Tâches planifiées</h4>
            </div>
            <activite-editor
              ref="activiteEditor"
              v-if="activeEvent != null"
              :item="activeEvent"
              btnText="Programmer une activité"
              @saved="createOrUpdateProgramme"
              title="Ajouter une activité planifiée"
            />
            <activite-report-editor
              v-if="activeEvent != null"
              :activite="activeEvent"
              ref="activiteReportEditor"
              @saved="createOrUpdateProgramme"
              title="Compte rendu d'activité"
            />
            <activite-participant-list-dialog
              v-if="activeEvent != null"
              :activite="activeEvent"
              ref="participantListDialog"
              btnText="Programmer une activité"
              @saved="createOrUpdateProgramme"
              title="Liste des participants"
              :inline="false"
            />
            <activite-presence-list-dialog
              v-if="activeEvent != null"
              :activite="activeEvent"
              ref="presenceListDialog"
              btnText="Programmer une activité"
              @saved="createOrUpdateProgramme"
              title="Liste des participants"
            />
            <button class="btn btn-warning style_btn ms-4" @click="ajouterProgramme">
              Programmer une activité
            </button>
          </div>
        </template>
        <template #content>
          <div class="d-flex justify-content-between mb-2">
            <span class="p-buttonset">
              <PButton icon="pi pi-chevron-left" @click.prevent.stop="goToPrevDate" />
              <PButton icon="pi pi-chevron-right" @click.prevent.stop="goToNextDate" />
            </span>
            <PButton @click.prevent.stop="goToDay" label="Aujoud'hui" />
            <PCalendar
              v-model="calendarActiveDate"
              view="month"
              dateFormat="MM yy"
              @input="goToDate"
              :yearNavigator="true"
              yearRange="2021:2080"
            />
            <span class="p-buttonset">
              <PButton
                :class="{ 'p-button-outlined': opt.value != currentView }"
                v-for="(opt, i) in calendarViewOptions"
                :key="i"
                @click.prevent.stop="calendarViewModeChanged(opt.value)"
                :label="opt.name"
              />
            </span>
          </div>
          <FullCalendar
            ref="fCalendar"
            :options="calendarOptions"
            @eventRender="eventRender"
          />
        </template>
      </p-card>
      <template v-if="!mxLoading">
        <div class="bg-"></div>
      </template>
    </div>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import ActiviteEditor from "../../../components/espace/common/ActiviteEditor.vue";
import ActiviteReportEditor from "../../../components/espace/common/ActiviteReportEditor.vue";
import ActiviteParticipantListDialog from "../../../components/espace/common/ActiviteParticipantListDialog.vue";
import ActivitePresenceListDialog from "../../../components/espace/common/ActivitePresenceListDialog.vue";
import ActiviteStatistic from "../../../components/statistic/activite/ActiviteStatistic.vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { mapActions, mapGetters } from "vuex";
import frLocale from "@fullcalendar/core/locales/fr";
import ActiviteParticipantList from "./activite/ActiviteParticipantList.vue";
import CDetailViewer from "../../../components/common/CDetailViewer.vue";
import ActiviteDetail from "./activite/ActiviteDetail";

// import bootstrap5Plugin from '@fullcalendar/bootstrap5'
// import { QCalendar } from '@quasar/quasar-ui-qcalendar'

export default {
  components: {
    FullCalendar,
    // eslint-disable-next-line vue/no-unused-components
    ActiviteStatistic,
    ActiviteParticipantListDialog,
    ActiviteParticipantList,
    ActivitePresenceListDialog,
    ActiviteReportEditor,
    ActiviteEditor,
    CDetailViewer,
    ActiviteDetail,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      currentView: "dayGridMonth",
      calendarActiveDate: new Date(),
      calendarViewOptions: [
        { name: "Mois", value: "dayGridMonth" },
        { name: "Semaine", value: "timeGridWeek" },
        { name: "Année", value: "timeGridDay" },
      ],
      calendarOptions: {
        // themeSystem: 'bootstrap5',
        headerToolbar: false,
        footerrToolbar: false,
        themeSystem: "",
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        initialView: "dayGridMonth",
        weekends: true, // initial value
        dateClick: this.handleDateClick,
        editable: false,
        selectable: true,
        displayEventTime: true,
        allDaySlot: false,
        // buttonText: 'Mois',
        // weekText: 'Sem',
        // headerToolbar: {
        //   left: "prev,next,today",
        //   center: "title",
        //   right: "dayGridMonth,timeGridWeek,timeGridDay",
        // },
        locales: [frLocale],
        config: {
          locale: "fr",
        },
        events: [],
        visibleRange: function (currentDate) {
          return {
            start: currentDate.clone().subtract(1, "days"),
            end: currentDate.clone().add(3, "days"), // exclusive end, so 3
          };
        },
        eventContent: (arg) => {
          let activite = arg.event.extendedProps;

          let eventWrapper = document.createElement("div");
          let bg = "event-box-pending text-white";

          if (activite.statusActivite == "TENUE") {
            bg = "event-box-done text-white";
          } else if (activite.statusActivite == "NON_TENUE") {
            bg = "event-box-missed text-white";
          } else if (activite.statusActivite == "CLOTUREE") {
            bg = "event-box-reported text-white";
          }

          eventWrapper.setAttribute(
            "class",
            bg + " event-box text-white border d-fle border-radius-md text- px-2 py-1"
          );
          eventWrapper.setAttribute("style", "border-radius: 4px; width: 100%; text");

          const btnPartipants = document.createElement("button");
          btnPartipants.setAttribute("class", "btn btn-sm btn-warning p-1 py-0 ms-2");
          btnPartipants.setAttribute("title", "Voir les participants");
          // btnPartipants.innerHTML = '<i class="bi bi-people"></i>'
          btnPartipants.innerHTML = '<i class="bi bi-bell"></i>';
          btnPartipants.onclick = () => this.showEventParticipants(activite);

          const btnEnventHeld = document.createElement("button");
          btnEnventHeld.setAttribute("class", "btn btn-sm btn-light p-1 py-0 ms-2");
          // btnEnventHeld.setAttribute('title', 'Clôturer l\'activité')
          btnEnventHeld.innerHTML = '<i class="bi bi-check"></i>';
          btnEnventHeld.onclick = (evt) => this.renseignerTenueActivite(evt, activite);

          const btnEnventReport = document.createElement("button");
          btnEnventReport.setAttribute("class", "btn btn-sm btn-warning p-1 py-0 ms-2");
          btnEnventReport.innerHTML = '<i class="bi bi-ui-checks"></i>';
          btnEnventReport.onclick = () => this.remplirRapport(activite);

          const btnEnventInfo = document.createElement("button");
          btnEnventInfo.setAttribute("class", "btn btn-sm btn-warning p-1 py-0 ms-2");
          btnEnventInfo.innerHTML = '<i class="bi bi-info-circle"></i>';
          btnEnventInfo.onclick = () => this.showActiviteDetail(activite);

          if (activite.isUrgent) {
            eventWrapper.innerHTML = "urgent event";
          } else {
            let content = `
              <div class="d-flex justify-content-between">
                <div class="text-wrap">${activite.titre} <span class="text">${
              activite.statusActivite
            }</span></div>
                <span>${this.$moment(activite.datePrevue).format("HH:mm")} </span>
                `;

            if (
              activite.typeActivite != null &&
              activite.typeActivite.code == "ENTRETIEN_DIAGNOSTIC"
            ) {
              content += `<span class="${"evaluable"}">E</span>`;
            }

            content += `
              </div>
            `;
            eventWrapper.innerHTML = content;
            // <span @click="ajouterProgramme" class="position- top-0 start-100 translate-middle badge rounded-pill bg-danger">
            //   ${activite?.participants?.length}
            // </span>
            eventWrapper.appendChild(btnPartipants);
            if (activite.statusActivite == "CREE")
              eventWrapper.appendChild(btnEnventHeld);
            if (
              activite.statusActivite == "TENUE" ||
              activite.statusActivite == "CLOTUREE"
            )
              eventWrapper.appendChild(btnEnventReport);
            eventWrapper.appendChild(btnEnventInfo);
          }

          let arrayOfDomNodes = [eventWrapper];
          return { domNodes: arrayOfDomNodes };
        },
      },
      myFiles: [],
      selected: [],
      activeEvent: null,
      activeActivite: null,
      activeTab: 0,
      search: "",
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "card",
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      exportActions: [
        // {
        // 	label: 'PDF',
        // 	icon: 'pi pi-file-pdf',
        // 	command: () => {
        //     const data = XLSX.utils.json_to_sheet(this.tableData.map(el => ({ ...el, ...el.azoli })))
        //     const wb = XLSX.utils.book_new()
        //     XLSX.utils.book_append_sheet(wb, data, 'data')
        //     XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
        // 	}
        // },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
            // this.mxExportToExcel().then(items => {
            //   const data = XLSX.utils.json_to_sheet(items.map(el => ({
            //     ...el,
            //     ...el.azoli,
            //     commune: el.commune.name,
            //     departement: el.departement.name,
            //     village: el.ville.name,
            //     arrondissement: el.arrondissement.name,
            //   })))
            //   const wb = XLSX.utils.book_new()
            //   XLSX.utils.book_append_sheet(wb, data, 'data')
            //   XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
            // })
          },
        },
      ],
      searchFields: ["nom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "telephone",
          visible: this.$can("filter.phone", "Azoli"),
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          label: "Département",
          value: "departement",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          label: "AE (Nom & prénom)",
          value: "ae",
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          label: "Arrondissement",
          value: "arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          label: "Village/Quartier de ville",
          value: "ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          label: "Métier Appris",
          value: "metierAppris",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          label: "Métier Désiré",
          value: "metierDesire",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "nom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    calendarActiveDate() {
      this.loadData();
    },
    activitePlanifies() {
      this.calendarOptions.events = this.activitePlanifies.map((el) => ({
        title: el.titre,
        date: el.datePrevue,
        start: el.datePrevue,
        extendedProps: {
          ...el,
        },
      }));
    },
  },
  computed: {
    ...mapGetters({
      activitePlanifies: "activitePlanifie/activitePlanifies",
    }),
    emptyEvent() {
      return {
        datePrevue: null,
        typeActivite: null,
        participants: [],
      };
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateActivitePlanifie: "activitePlanifie/createOrUpdateActivitePlanifie",
      updateActivitePlanifieStatus: "activitePlanifie/updateActivitePlanifieStatus",
      fetchActivitePlanifies: "activitePlanifie/fetchActivitePlanifies",
    }),
    loadData() {
      this.mxInitializePaginator({
        queryModel: "agent",
        search: this.search,
        fetcherMethod: "activitePlanifie/fetchActivitePlanifies",
        dataGetter: "activitePlanifie/activitePlanifies",
        paginationGetter: "activitePlanifie/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 100,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = new Date(
            Date.UTC(
              this.calendarActiveDate.getFullYear(),
              this.calendarActiveDate.getMonth(),
              1
            )
          )
            .toISOString()
            .substring(0, 10);

          const dateFin = new Date(
            Date.UTC(
              this.calendarActiveDate.getFullYear(),
              this.calendarActiveDate.getMonth() + 1,
              0
            )
          )
            .toISOString()
            .substring(0, 10);

          return `datePrevue=${dateDeb}T00:00:00Z&datePrevue=${dateFin}T00:00:00Z`;
        },
        searchFields: this.searchFields,
      });
    },
    eventRender(view, event) {},
    showActiviteDetail(activite) {
      this.activeActivite = { ...activite };
      this.$refs.detailViewer.show();
    },
    goToDay() {
      // let calendarApi = this.$refs.fCalendar.getApi()
      // calendarApi.today()
      this.calendarActiveDate = new Date();
    },
    goToPrevDate() {
      let calendarApi = this.$refs.fCalendar.getApi();
      calendarApi.prev();
      this.calendarActiveDate = calendarApi.currentData.dateProfile.currentRange.start;
    },
    goToNextDate() {
      let calendarApi = this.$refs.fCalendar.getApi();
      calendarApi.next();
      this.calendarActiveDate = calendarApi.currentData.dateProfile.currentRange.start;
    },
    goToDate(date) {
      let calendarApi = this.$refs.fCalendar.getApi();
      calendarApi.gotoDate(new Date(this.$moment(date).format("YYYY-MM-DDTHH:mm")));
    },
    calendarViewModeChanged(mode) {
      this.currentView = mode;
      let calendarApi = this.$refs.fCalendar.getApi();
      calendarApi.changeView(mode);
      // if (mode == 'dayGridMonth') {
      //   calendarApi.next()
      // }
      // else if (mode == 'timeGridWeek'){
      //   calendarApi.prev()
      // }
      // else if (mode == 'timeGridDay'){
      //   calendarApi.today()
      // }
    },
    handleDateClick: function (arg) {
      this.setActiveEvent({
        ...this.emptyEvent,
        // datePrevue: arg.dateStr,
        datePrevue: new Date(this.$moment(arg.dateStr).format("YYYY-MM-DDTHH:mm")),
      });
      this.$refs.activiteEditor.show();
    },
    renseignerTenueActivite(event, activite) {
      // if(activite) return
      this.$confirm.require({
        group: "confirmPopup",
        target: event.currentTarget,
        message: "L'activité a-t-elle pu tenir?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.createOrUpdateActivitePlanifie({
            ...activite,
            statusActivite: "TENUE",
          }).then(() => {
            this.$toast.success("Opération réussie!", {
              position: "top-right",
              duration: 5000,
            });
            this.$refs.activiteStatistic.reload();
          });
        },
        reject: () => {
          this.createOrUpdateActivitePlanifie({
            ...activite,
            statusActivite: "NON_TENUE",
          }).then(() => {
            this.$refs.activiteStatistic.reload();
            // this.$toast.success("Opération réussie!", {
            //   position: "top-right",
            //   duration: 5000
            // })
          });
        },
      });
    },
    setActiveEvent(activite) {
      this.activeEvent = { ...activite };
    },
    remplirRapport(activite) {
      this.setActiveEvent(activite);
      this.$refs.activiteReportEditor.show();
    },
    ajouterProgramme() {
      if (this.activeEvent == null) {
        this.setActiveEvent({
          ...this.emptyEvent,
          // datePrevue: arg.dateStr,
          datePrevue: new Date(),
        });
      }
      // this.setActiveEvent(activite)
      this.$refs.activiteEditor.show();
    },
    createOrUpdateProgramme(activite) {
      this.createOrUpdateActivitePlanifie(activite).then(() => {});
    },
    showEventParticipants(activite) {
      this.setActiveEvent(activite);
      this.$refs.participantListDialog.show();
    },
    toggleWeekends: function () {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
    },
  },
};
</script>

<style>
.event-box-pending {
  background: #42a5f5;
  background: #66bb6a;
  background: #26c6da;
  background: #7e57c255;
  background: #7e57c245;
  color: #7e57c2 !important;
}

.event-box-done {
  /* background: #66BB6A45;
     color: #66BB6A!important; */
  background: #00b68345;
  color: #00b683 !important;
  color: rgba(0, 0, 0, 0.57) !important;
}
.event-box-danger {
  background: #ffa72645;
  color: #ffa726ff !important;
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-missed {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #fe005345;
  /* color: #FE0053FF!important; */
  /* color: rgba(0, 0, 0, 0.87); */
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-pending {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #485ffd45;
  color: #485ffdff !important;
}

.event-box-reported {
  background: #085e7d45 !important;
  color: #085e7d !important;
  background: #df711b45 !important;
  color: #df711b !important;
}

.event-box-total {
  background: #22006945 !important;
  color: #220069 !important;
}

.event-box {
  font-weight: 430;
  /* color: #222222!important; */
}

.p-card-content {
  padding: 0px;
}

.evaluable {
  background: rgb(253, 40, 40);
  /* background: #dc3535; */
  color: transparent;
  position: absolute;
  right: 10px;
  bottom: 10px;
  height: 10px;
  border-radius: 50%;
  width: 10px;
}
</style>
